<template>
    <HeaderComponent />
    <BreadcrumbsComponent 
        pageName="Поддержка"
        pageTitle="Поддержка"
    />
    <ServicesComponent />

    <section class="cta-section cta-our-team cta-hp-3 section-box mb-5" style="background-color: grey">
        <div class="container">
            <div class="cta-content">
                <div class="cta-text">
                    <p>Восстанавливаем трансмисcии, двигатели, крупные узлы и агрегаты с гарантией 1000 моточасов</p>
                    <span>Ремонт узлов</span>
                </div>
                <a href="#" class="au-btn au-btn-green btn-small">Связаться</a>
            </div>
        </div>
    </section>
    <FooterComponent />
</template>

<script>
import HeaderComponent from '@/components/Header/HeaderComponent.vue'
import FooterComponent from '@/components/Footer/FooterComponent.vue'
import BreadcrumbsComponent from '@/components/Widgets/BreadcrumbsComponent.vue'
import ServicesComponent from '@/components/Widgets/ServicesComponent.vue'


export default {
    name: "SupportView",
    components: {
        HeaderComponent,
        FooterComponent,
        BreadcrumbsComponent,
        ServicesComponent,
    },
    
    watch: {
      '$route': {
          immediate: true,
          handler() {
              document.title = 'Поддержка'
          },
      },
  },
}
</script>