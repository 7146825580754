import Axios from 'axios'
import {backendPath} from "@/main.js"

export default {
    state: {
        sessionToken: "",
        sessionTokenLoading: false
    },
    getters: {
        sessionToken: state => {
            return state.sessionToken
        },
        sessionTokenLoading: state => {
            return state.sessionTokenLoading
        }
    },
    mutations: {
        loadSessionToken (state, payload) {
            state.sessionToken = payload
        },
        sessionTokenLoadingSwitch (state, payload) {
            state.sessionTokenLoading = payload
        }
    },
    actions: {
        async getSessionToken({commit}) {
            commit('sessionTokenLoadingSwitch', true)
            let url = `${backendPath}/api/v1/auth/get-token/`
            await Axios({
                method: 'get',
                url: url,
            }).then(response => {
                let sessionToken = response.data.data.token
                commit('loadSessionToken', sessionToken)
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                commit('sessionTokenLoadingSwitch', false)
            })
        },
        setSessionToken ({commit}, sessionToken) {
            commit('loadSessionToken', sessionToken)
        }
    }
}