<template>
    <section class="cart-page section-box">
        <div v-if="!cartLoading && cartQty < 1" class="container">
                <h2 class="special-heading">В вашей корзине нет товаров!</h2>
                <div id="client-hp-1" class="owl-carousel owl-theme owl-loaded owl-drag">
                </div>
                <div style="display: flex; justify-content: center;">
                    <router-link :to="{ name: 'catalog', query: { page: 1}}" class="au-btn au-btn-green btn-small">В каталог</router-link>
                </div>
        </div>    
        <div v-if="cartQty > 0" class="container">
            <div class="woocommerce">
                <div class="woo-cart-form">
                    <table v-if="cartLoading" class="shop_table shop_table_responsive cart woocommerce-cart-form__contents">
                        <tbody>
                            <tr 
                                v-for="item in previewCount"
                                :key="item.id"
                            >   
                                <td><n-skeleton height="40px" round /></td>
                                <td><n-skeleton height="40px" round /></td>
                                <td><n-skeleton height="40px" round /></td>
                                <td><n-skeleton height="40px" round /></td>
                            </tr>
                        </tbody>
                    </table>
                    <table v-else class="shop_table shop_table_responsive cart woocommerce-cart-form__contents">
                        <thead>
                            <tr>
                                <th class="product-name">Артикул</th>
                                <th class="product-name">Товар</th>
                                <th class="product-quantity">Количество</th>
                                <th class="product-remove"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr 
                                v-for="cartItem in cart"
                                :key="cartItem.id"
                                class="woocommerce-cart-form__cart-item cart_item"
                            >
                                <UserCartGoodComponent 
                                    :goodInfo="cartItem.good"
                                    :qnt="cartItem.qnt"
                                />
                            </tr>
                            <tr>
                                <td colspan="6" class="actions">
                                    <button @click="showModal = true" class="button au-btn au-btn-green">Оформить заказ</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>
    <n-modal v-model:show="showModal">
        <n-card
            style="width: 600px"
            title=""
            :bordered="false"
            size="huge"
            role="dialog"
            aria-modal="true"
        >
        <div class="form-input" :class="{ error: nameError }">
            <input 
                @input="checkName" 
                v-model="inputName" 
                type="text" 
                placeholder="Имя"
            >
        </div>
        <div class="form-input" :class="{ error: emailError }">
            <input 
                v-model="inputEmail" 
                @blur="checkEmail" 
                type="text" 
                placeholder="Email"
            >
        </div>
        <div class="form-input" :class="{ error: phoneError }">
            <input 
                v-phone
                v-model="inputPhone" 
                @blur="checkPhone"
                type="text" 
                placeholder="Телефон"
            >
        </div>
        <template #footer>
            <div v-if="sendCartLoading" class="row justify-content-center">
                <button class="as-button-confirm" style="padding: 18px 86px;">
                    <n-space>
                        <n-spin size="large" />
                    </n-space>
                </button>
            </div>
            <div v-else class="row justify-content-center">
                <button @click="sendCart" class="as-button-confirm">Отправить заказ</button>
            </div>
        </template>
        </n-card>
    </n-modal>
</template>

<script>
import UserCartGoodComponent from '@/components/User/UserCartGoodComponent.vue'
import { defineComponent } from 'vue'
export default defineComponent({
    name: "UserCartComponent",
    components: {
        UserCartGoodComponent,
    },
    data () {
        return {
            previewCount: 6,
            sendCartLoading: false,
            showModal: false,
            nameError: false,
            emailError: false,
            phoneError: false,
            inputName: "",
            inputEmail: "",
            inputPhone: "",
        }
    },
    computed: {
        sessionToken () {
            return this.$store.getters.sessionToken
        },
        cart () {
            return this.$store.getters.cart
        },
        cartQty () {
            return this.$store.getters.cartQty
        },
        cartLoading () {
            return this.$store.getters.cart_loading
        },
        cleanPhone () {
            return this.inputPhone.replace("(", "").replace(")", "").replace(" ", "").replace("-", "")
        }
    },
    methods: {
        sendCart () {
            this.checkEmail()
            this.checkName()
            this.checkPhone()
            if (!this.nameError && !this.emailError && !this.phoneError) {
                this.sendCartLoading = true
                this.$store.dispatch('sendCart', 
                {   
                    name: this.inputName,
                    email: this.inputEmail,
                    phone: this.cleanPhone,
                    sessionToken: this.sessionToken,
                }).then(() => {
                    this.sendCartLoading = false
                    this.showModal = false
                })
            }
        },
        checkName () {
            if (this.inputName == "") {
                this.nameError = true
            } else {
                this.nameError = false
            }
        },
        checkEmail () {
            if (!this.isValidEmail()) {
                this.emailError = true
            } else {
                this.emailError = false
            }
        },
        checkPhone () {
            if (!this.isValidPhone()) {
                this.phoneError = true
            } else {
                this.phoneError = false
            }
        },
        isValidEmail() {
            return /^[^@]+@\w+(\.\w+)+\w$/.test(this.inputEmail)
        },
        isValidPhone() {
            return /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11}(\s*)?$/.test(this.cleanPhone)
        },
    },
    directives: {
        phone: {
            mounted(el) {
                function replaceNumberForInput(value) {
                    let val = ''
                    const x = value.replace(/\D/g, '').match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/)
                    if (!x[2] && x[1] !== '') {
                        val = x[1] === '8' ? x[1] : '8' + x[1]
                    } else {
                        val = !x[3] ? x[1] + x[2] : x[1] + '(' + x[2] + ') ' + x[3] + (x[4] ? '-' + x[4] : '')
                    }
                    return val
                }
                function replaceNumberForPaste(value) {
                    const r = value.replace(/\D/g, '')
                    let val = r
                    if (val.charAt(0) !== '8') {
                      val = '8' + val.slice(1)
                    }
                    return replaceNumberForInput(val)
                }
                el.oninput = function(e) {
                    if (!e.isTrusted) {
                        return
                    }
                    this.value = replaceNumberForInput(this.value)
                }
                el.onpaste = function() {
                    setTimeout(() => {
                        const pasteVal = el.value
                        this.value = replaceNumberForPaste(pasteVal)
                    })
                }
                el.onchange = function() {
                    setTimeout(() => {
                        const pasteVal = el.value
                        this.value = replaceNumberForPaste(pasteVal)
                    })
                }
            }
        }
    }
})
</script>

<style scoped>
.as-button-confirm {
    background-color: #2c53d2;
    border: none;
    color: #fff;
    padding: 25px;
    text-transform: none;
    letter-spacing: 0;
    line-height: 0;
    cursor: pointer;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    font-size: 16px;
    font-weight: 600;
}
.form-input {
    margin-bottom: 20px;
}
.form-input.error input {
    border-color: #ff0909 !important;
}
.form-input input {
	padding: 19px 20px;
    color: #4f4f4f;
}
</style>