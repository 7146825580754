<template>
    <div class="col-12 col-md-3 as-product-vendor-code">
        <p><span class="">Арт:</span><router-link :to="{ name: 'good', params: { id:goodInfo.id} }">{{ goodInfo.art }}</router-link></p>
    </div>
    <div class="col-12 col-md-5 as-product-name">
        <p><router-link :to="{ name: 'good', params: { id:goodInfo.id} }">{{ goodInfo.name }}</router-link></p>
    </div>
    <!-- <div class="col-12 col-md-2 as-product-price">
        <p><span class="">Цена:</span> {{ goodInfo.price }}</p>
    </div> -->
    <div class="col-12 col-md-2 as-product-availability">
        <p><span class="">Остаток:</span> {{ goodInfo.balance }}</p>
    </div>
    <div class="col-12 col-md-2 as-product-action">
        <p>
            <button
                v-if="loading" 
                class="au-btn au-btn-green btn-small reply"
            >
                <n-space>
                    <n-spin size="small" />
                </n-space>
            </button>
            <button 
                v-else 
                @click="addToCart"
                class="au-btn au-btn-green btn-small reply"
            >
                <i class="fas fa-cart-plus"></i>
            </button>
        </p>    
    </div>
</template>

<script>
export default {
    name: "CatalogGoodComponent",
    props: ["goodInfo"],
    data () {
        return {
            loading: false,
        }
    },
    computed: {
        sessionToken () {
            return this.$store.getters.sessionToken
        }
    },
    methods: {
        addToCart () {
            this.loading = true
            this.$store.dispatch('addSetDelCartItem', 
            {
                good_id: this.goodInfo.id,
                quantity: 1,
                sessionToken: this.sessionToken,
                action: 'add'
            }).then(() => {
                this.loading = false
            })
        },
    }
}
</script>
