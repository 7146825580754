<template>
    <div class="row as-product-card-list">
        <div class="row as-product-card product-card-header">
            <div class="col-3 col-md-3 as-product-vendor-code">
                <p>Артикул</p>
            </div>
            <div class="col-9 col-md-5 as-product-name">
                <p>Наименование</p>
            </div>
            <!-- <div class="col-6 col-md-2 as-product-price">
                <p>Цена</p>
            </div> -->
            <div class="col-6 col-md-2 as-product-availability">
                <p>Остаток</p>
            </div>
            <div class="col-12 col-md-2 as-product-action"> 
            </div>
        </div>
        <div
            v-for="item in demoContentCount" 
            :key="item.id"
            class="row as-product-card">
            <div class="col-12 col-md-3 as-product-vendor-code">
                <p><n-skeleton  :sharp="false" size="medium" /></p>
            </div>
            <div class="col-12 col-md-5 as-product-name">
                <p><n-skeleton  :sharp="false" size="medium" /></p>
            </div>
            <!-- <div class="col-12 col-md-2 as-product-price">
                <p><n-skeleton  :sharp="false" size="medium" /></p>
            </div> -->
            <div class="col-12 col-md-2 as-product-availability">
                <p><n-skeleton  :sharp="false" size="medium" /></p>
            </div>
            <div class="col-12 col-md-2 as-product-action">
                <p>
                    <n-skeleton  :sharp="false" size="medium" />
                </p>    
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContentPreview",
    data () {
        return {
            demoContentCount: 15,
        }
    }
}
</script>