<template>
    <section class="breadcrumb-shop-products breadcrumb-section section-box" style="background-color: grey;">
        <div class="container">
            <div class="breadcrumb-inner">
                <h1 v-if="pageTitle">{{ pageTitle }}</h1>
                <ul class="breadcrumbs">
                    <li>
                        <router-link 
                            class="breadcrumbs-1" 
                            :to="{ name: 'home' }"
                        >Главная
                        </router-link>
                    </li>
                    <li><p class="breadcrumbs-2">{{ pageName }}</p></li>
                </ul>
            </div>	
        </div>
    </section>
</template>

<script>
export default {
    name: "BreadcrumbsComponent",
    props: ["pageName", "pageTitle"]
}
</script>