<template>
    <div class="show-mobile-header">
        <!-- Logo And Hamburger Button -->
        <div class="mobile-top-header">
            <div class="logo-mobile">
                <router-link :to="{ name:'home' }"><img :src="logoImage" alt="logo"></router-link>
            </div>
            <button 
                :class="{ 'is-active': hamburgerIsActive }"
                @click="hamburgerIsActive = !hamburgerIsActive"
                class="hamburger hamburger--spin hidden-tablet-landscape-up" id="toggle-icon"
            >
                <span class="hamburger-box">
                    <span class="hamburger-inner"></span>
                </span>
            </button>
            <div class="cart-button mobile-cart-button">
                <router-link :to="{ name: 'cart' }">
                    <div class="cart-form">
                        <n-space :size="24">
                            <n-badge :value="cartQty" show-zero>
                                <span class="cart-icon">
                                    <i class="fas fa-shopping-cart"></i>
                                </span>
                            </n-badge>
                        </n-space>
                    </div>
                </router-link>
            </div>
        </div>
        <!-- Au Navbar Menu -->
        <transition name="fade">
            <div v-if="hamburgerIsActive" class="au-navbar-mobile navbar-mobile-1">
                <div class="au-navbar-menu">
                    <ul>
                        <li class="drop">
                            <router-link class="drop-link" :to="{ name: 'home' }">Главная</router-link> 												
                        </li>
                        <li class="drop">							
                            <a class="drop-link" href="#">
                                Поддержка
                            </a>							
                        </li>
                        <li class="drop">							
                            <a class="drop-link" href="#">
                                Сервис
                            </a>							
                        </li>
                        <li class="drop">							
                            <router-link class="drop-link" :to="{ name: 'catalog', query: {page: 1} }">Запчасти</router-link>							
                        </li>
                        <li class="drop">							
                            <a class="drop-link" href="#">
                                О нас
                            </a>							
                        </li>
                        <li class="drop">							
                            <a class="drop-link" href="#">
                                Контакты
                            </a>							
                        </li>
                    </ul>
                </div>				
            </div>
        </transition>    
    </div>
</template>

<script>
import logoImage from "@/assets/images/logo/logo-black.png"
export default {
    name: "MobileHeaderComponent",
    data () {
        return {
            logoImage,
            hamburgerIsActive: false
        }
    },
    computed: {
        cartQty () {
            return this.$store.getters.cartQty
        },
    }
}
</script>

<style scoped>
    .fade-enter-active,
    .fade-leave-active {
    transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }
</style>