<template>
    <footer class="footer">
        <div class="footer-section section-box" style="background-color: grey;">
			<div class="container">
				<div class="row">
                    <CompanyInfoComponent />
                    <FooterMenuComponent />
                    <ImportantLinksComponent />
                    <FooterContactsComponent />
                </div>
            </div>
        </div>            
        <SubFooterComponent />
    </footer>
</template>

<script>
import SubFooterComponent from '@/components/Footer/SubFooterComponent.vue'
import CompanyInfoComponent from '@/components/Footer/CompanyInfoComponent.vue'
import FooterMenuComponent from '@/components/Footer/FooterMenuComponent.vue'
import ImportantLinksComponent from '@/components/Footer/ImportantLinksComponent.vue'
import FooterContactsComponent from '@/components/Footer/FooterContactsComponent.vue'

export default {
    name: "FooterComponent",
    components: {
        SubFooterComponent,
        CompanyInfoComponent,
        FooterMenuComponent,
        ImportantLinksComponent,
        FooterContactsComponent
    }
}
</script>