<template>
    <section class="shop-product-page blog-left-section section-box">
        <div class="container-fluid">
            <div class="woocommerce">
                <div class="row">
                    <SearchComponent />
                    <div class="col-12">
                        <div class="content-area">
                            <!-- <div class="storefront-sorting">
                                <p class="woocommerce-result-count">Showing 1–9 of 18 results</p>
                                <form class="woocommerce-ordering" method="get">
                                    <select name="orderby" class="orderby">
                                        <option value="default" selected="selected">Default Sorting</option>
                                        <option value="popularity">Sort by popularity</option>
                                        <option value="rating">Sort by average rating</option>
                                        <option value="date">Sort by newness</option>
                                        <option value="price">Sort by price: low to high</option>
                                        <option value="price-desc">Sort by price: high to low</option>
                                    </select>
                                </form>
                            </div> -->
                            <ContentPreview v-if="goods_list_loading" />
                            <div v-else class="row as-product-card-list">
                                <div class="row as-product-card product-card-header">
                                    <div class="col-3 col-md-3 as-product-vendor-code">
                                        <p>Артикул</p>
                                    </div>
                                    <div class="col-9 col-md-5 as-product-name">
                                        <p>Наименование</p>
                                    </div>
                                    <!-- <div class="col-6 col-md-2 as-product-price">
                                        <p>Цена</p>
                                    </div> -->
                                    <div class="col-6 col-md-2 as-product-availability">
                                        <p>Остаток</p>
                                    </div>
                                    <div class="col-12 col-md-2 as-product-action"> 
                                    </div>
                                </div>
                                <div 
                                    v-for="good in goods_list"
                                    :key="good.id"
                                    class="row as-product-card">
                                    <CatalogGoodComponent
                                        :goodInfo="good"
                                    >
                                    </CatalogGoodComponent>
                                </div>
                            </div>
                        </div>
                        <CatalogPagination />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import SearchComponent from '@/components/Catalog/SearchComponent.vue'
import ContentPreview from '@/components/Catalog/ContentPreview.vue'
import CatalogPagination from '@/components/Catalog/CatalogPagination.vue'
import CatalogGoodComponent from '@/components/Catalog/CatalogGoodComponent.vue'
export default {
    name: "ContentComponent",
    components: {
        SearchComponent,
        ContentPreview,
        CatalogPagination,
        CatalogGoodComponent
    },
    computed: {
        goodsQty () {
            return this.$store.getters.goods_qty
        },
        goods_list () {
            return this.$store.getters.goods_list
        },
        goods_list_loading () {
            return this.$store.getters.goods_list_loading
        }
    }    
}
</script>

<style scoped>

</style>