<template>
    <div></div>
</template>

<script>
    import { defineComponent } from 'vue'
    import { useMessage } from 'naive-ui'
    export default defineComponent({
        name: "MessageComponent",
        setup () {
            const message = useMessage()
            return {
                success (text) {
                    message.success(
                        `${text}`,
                        {
                            keepAliveOnHover: true
                        }
                    )
                },
                info(text) {
                    message.info(
                        `${text}`,
                        {
                            keepAliveOnHover: true
                        }
                    )
                },
            }
        },
        computed: {
            sendCartMessage () {
                return this.$store.getters.sendCartResultMessage
            },
            addCartItemMessage () {
                return this.$store.getters.addCartItemMessage
            }
        },
        watch: {
            sendCartMessage: {
                deep: true,
                handler() {
                    if (this.sendCartMessage != "") {
                        this.success(this.sendCartMessage)
                    }
                }
            },
            addCartItemMessage: {
                deep: true,
                handler() {
                    if (this.addCartItemMessage != "") {
                        this.info(this.addCartItemMessage)
                    }
                }
            },
        },
    })
</script>