import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import naive from "naive-ui"

export const backendPath = 'https://back.ab-service.annasoft.site'
export const cookiesToken = 'abservice-2bbf1485-fa82-488e-8bfe-4a2cb68a7a4d'

createApp(App).use(router).use(store).use(naive).mount('#app')
