<template>
    <div class="col-lg-3 col-xl-3 col-md-6 col-sm-6 col-12">
        <div class="footer-item">
            <router-link :to="{ name: 'home' }"><img :src="logoImage" alt="logo"></router-link>
            <p>
                ООО «АБ Сервис» на рынке сервисных услуг по ремонту техники, применяемой в Аграрном секторе экономики с 2010 года.
                Наша компания - гарант качественного сервиса по ремонту техники и машин к Вашим услугам! 
            </p>
            <div class="footer-socials">
                <a href="#"><i class="fab fa-facebook-f"></i></a>
                <a href="#"><i class="fab fa-whatsapp"></i></a>
                <a href="#"><i class="fab fa-twitter"></i></a>
                <a href="#"><i class="fab fa-behance"></i></a>
            </div>
        </div>
    </div>
</template>

<script>
import logoImage from "@/assets/images/logo/logo-white.png"
export default {
    name: "CompanyInfoComponent",
    data () {
        return {
            logoImage
        }
    }
}
</script>