<template>
    <section class="services-section-hp-3 section-box">
        <div class="container">
            <h2 class="special-heading">Диагностика узлов</h2>
            <div class="row">
                <!-- Services-1 -->
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <div class="services-content" @mouseover="ecuServiceHover=true" @mouseleave="ecuServiceHover=false">
                        <div class="services-icon">
                            <a href="#">
                                <img v-if="ecuServiceHover" :src="ecuServiceImageWhite" alt="services-6">
                                <img v-else :src="ecuServiceImageBlue" alt="services-6">
                            </a>
                        </div>
                        <div class="services-text">
                            <span><a href="#">ECU/TCU диагностика</a></span>
                        </div>
                    </div>
                </div>
                <!-- Services-2 -->
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <div class="services-content" @mouseover="electricServiceHover=true" @mouseleave="electricServiceHover=false">
                        <div class="services-icon">
                            <a href="#">
                                <img v-if="electricServiceHover" :src="electricServiceImageWhite" alt="services-6">
                                <img v-else :src="electricServiceImageBlue" alt="services-6">
                            </a>
                        </div>
                        <div class="services-text">
                            <span><a href="#">Электрика, электроника</a></span>
                        </div>
                    </div>
                </div>
                <!-- Services-3 -->
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <div class="services-content" @mouseover="enginServiceHover=true" @mouseleave="enginServiceHover=false">
                        <div class="services-icon">
                            <a href="#">
                                <img v-if="enginServiceHover" :src="enginServiceImageWhite" alt="services-3">
                                <img v-else :src="enginServiceImageBlue" alt="services-3">
                            </a>
                        </div>
                        <div class="services-text">
                            <span><a href="#">Двигатель</a></span>
                        </div>
                    </div>
                </div>
                <!-- Services-4 -->
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <div class="services-content" @mouseover="gidroServiceHover=true" @mouseleave="gidroServiceHover=false">
                        <div class="services-icon">
                            <a href="#">
                                <img v-if="gidroServiceHover" :src="gidroServiceImageWhite" alt="services-6">
                                <img v-else :src="gidroServiceImageBlue" alt="services-6">
                            </a>
                        </div>
                        <div class="services-text">
                            <span><a href="#">Гидравлика</a></span>
                        </div>
                    </div>
                </div>
                <!-- Services-5 -->
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <div class="services-content" @mouseover="acServiceHover=true" @mouseleave="acServiceHover=false">
                        <div class="services-icon">
                            <a href="#">
                                <img v-if="acServiceHover" :src="acServiceImageWhite" alt="services-6">
                                <img v-else :src="acServiceImageBlue" alt="services-6">
                            </a>
                        </div>
                        <div class="services-text">
                            <span><a href="#">Кондиционеры</a></span>
                        </div>
                    </div>
                </div>
                <!-- Services-6 -->
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <div class="services-content" @mouseover="transmissionServiceHover=true" @mouseleave="transmissionServiceHover=false">
                        <div class="services-icon">
                            <a href="#">
                                <img v-if="transmissionServiceHover" :src="transmissionServiceImageWhite" alt="services-6">
                                <img v-else :src="transmissionServiceImageBlue" alt="services-6">
                            </a>
                        </div>
                        <div class="services-text">
                            <span><a href="#">Трансмиссия</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import enginServiceImageBlue from "@/assets/images/icons/hp-3-service-blue-3.png"
import enginServiceImageWhite from "@/assets/images/icons/hp-3-service-while-3.png"
import transmissionServiceImageBlue from "@/assets/images/icons/hp-3-service-blue-2.png"
import transmissionServiceImageWhite from "@/assets/images/icons/hp-3-service-while-2.png"
import electricServiceImageBlue from "@/assets/images/icons/hp-3-service-blue-6.png"
import electricServiceImageWhite from "@/assets/images/icons/hp-3-service-while-6.png"
import acServiceImageBlue from "@/assets/images/icons/hp-3-service-blue-1.png"
import acServiceImageWhite from "@/assets/images/icons/hp-3-service-while-1.png"
import gidroServiceImageBlue from "@/assets/images/icons/hp-3-service-blue-4.png"
import gidroServiceImageWhite from "@/assets/images/icons/hp-3-service-while-4.png"
import ecuServiceImageBlue from "@/assets/images/icons/hp-3-service-blue-5.png"
import ecuServiceImageWhite from "@/assets/images/icons/hp-3-service-while-5.png"
export default {
    name: "ServicesComponent",
    data () {
        return {
            enginServiceHover: false,
            enginServiceImageBlue,
            enginServiceImageWhite,
            transmissionServiceHover: false,
            transmissionServiceImageBlue,
            transmissionServiceImageWhite,
            electricServiceHover: false,
            electricServiceImageBlue,
            electricServiceImageWhite,
            acServiceHover: false,
            acServiceImageBlue,
            acServiceImageWhite,
            gidroServiceHover: false,
            gidroServiceImageBlue,
            gidroServiceImageWhite,
            ecuServiceHover: false,
            ecuServiceImageBlue,
            ecuServiceImageWhite
        }
    },
}
</script>