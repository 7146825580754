<template>
    <HeaderComponent />
    <BreadcrumbsComponent 
        v-show="good"
        :pageName="good.name"
        :pageTitle="good.name"
    />
    <GoodDetailsComponent />
    <FooterComponent />
</template>

<script>
    import HeaderComponent from '@/components/Header/HeaderComponent.vue'
    import FooterComponent from '@/components/Footer/FooterComponent.vue'
    import BreadcrumbsComponent from '@/components/Widgets/BreadcrumbsComponent.vue'
    import GoodDetailsComponent from '@/components/Catalog/GoodDetails/GoodDetailsComponent.vue'

    export default {
        name: 'GoodDetailsView',
        components: {
            HeaderComponent,
            FooterComponent,
            BreadcrumbsComponent,
            GoodDetailsComponent
        },
        computed: {
            good () {
                return this.$store.getters.good
            }
        },
        mounted () {
            window.scrollTo({
                top: 200,
                behavior: 'smooth'
            })
        },
        watch: {
            good: {
                deep: true,
                handler() {
                    document.title = this.good.name
                }
            },
        },
    }
</script>