<template>
    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
        <div class="blog-left-sidebar widget-area">
            <div class="widget_search-1 widget_search">
                <div class="search-form">
                    <input 
                        v-model="searchInput"
                        @keyup.enter="showSearchResult" 
                        class="search-field" 
                        placeholder="Поиск..."
                    >
                    <button v-if="q" @click="clearSearch" class="search-clear">
                        <i class="la la-times"></i>
                    </button>
                    <button @click="showSearchResult" class="search-submit">
                        <i class="la la-search"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SearchComponent",
    data() {
        return {
            searchInput: ""
        }
    },
    computed: {
        q () {
            return this.$route.query.q
        }
    },
    methods: {
        showSearchResult: function(el) {
            el.preventDefault()
            if (this.searchInput != '') {
                this.$router.push({ name: 'catalog', query: { q:this.searchInput, page:1 } })
            }
        },
        clearSearch (el) {
            el.preventDefault()
            this.$router.push({ name: 'catalog', query: { page:1 } })
        }
    },
    watch: {
        '$route.query': {
            immediate: true,
            handler() {
                if (!this.q == '') {
                    this.searchInput = this.q
                } else {
                    this.searchInput = ""
                }
            },
        },
    },
}
</script>