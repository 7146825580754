<template>
    <HeaderComponent />
    <BreadcrumbsComponent 
        pageName="О нас"
        pageTitle="О нас"
    />
    <section class="blog-detail-section blog-left-section blog-right-section blog-v1-section blog-v2-section section-box">
        <div class="container">
            <div class="woocommerce">
                <div class="row">
                    <div class="col-12">
                        <div class="blog-left-detail">
                            <!-- Blog 1 -->
                            <div class="blog-detail">
                                <h3>ООО «АБ Сервис»</h3>
                                <p>
                                    на рынке сервисных услуг по ремонту техники, применяемой в Аграрном секторе экономики с 2010 года. 
                                </p>
                                <p>
                                    Наша компания оказывает спектр услуг по диагностике неисправностей:
                                </p>
                                <p>
                                <n-ul>
                                    <n-li>
                                        капитальному и текущему ремонту машин, узлов, агрегатов сельскохозяйственной и специальной технике любой сложности
                                    </n-li>
                                    <n-li>
                                        гарантийному ремонту двигателей и их постгарантийное обслуживание
                                    </n-li>
                                    <n-li>
                                        заправке и ремонту кондиционеров для всех марок техники и машин
                                    </n-li>
                                    <n-li>
                                        поставке запасных частей, масел и расходных материалов
                                    </n-li>
                                    <n-li>
                                        спектр технических консультаций по эксплуатации и техническому обслуживанию техники и машин
                                    </n-li>
                                </n-ul>
                                </p>

                                <p>
                                    Наша команда немногочисленна, но мы постоянно повышаем качественный уровень сервиса, эффективно используя внутренний потенциал и внешние ресурсы. Оснащенность автомобилей оборудованием и высокий профессиональный уровень специалистов позволяют выполнять полный, оперативный и качественный ремонт не только в стационарных ремонтных мастерских, но и в полевых условиях. 
                                </p>
                                <p>
                                    В целях упреждения необоснованных издержек и обеспечения нормативной выработки техники наши специалисты оказывают сервисные услуги качественно. В работе наши профессионалы следуют принципу «семь раз отмерь, один раз отрежь». 
                                </p>
                                <p>
                                    Во время полевых работ мы работаем без выходных, используя резерв складских запасов запасных частей, масел, технических жидкостей и самое главное умение, и желание специалистов оперативно с душой восстанавливать работоспособность техники и оборудования. Это позволяет в полном объеме и в установленные сроки выполнять запросы наших клиентов.
                                </p>
                                <p>
                                    В связи с сезонным характером получения доходов нашими партнерами Аграрного сектора, мы применяем гибкие условия оплаты услуг по ремонту и техническому обслуживанию сельскохозяйственной техники, прицепного и навесного оборудования.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <FooterComponent />
</template>

<script>
import HeaderComponent from '@/components/Header/HeaderComponent.vue'
import FooterComponent from '@/components/Footer/FooterComponent.vue'
import BreadcrumbsComponent from '@/components/Widgets/BreadcrumbsComponent.vue'
export default {
    name: "AboutView",
    components: {
        HeaderComponent,
        FooterComponent,
        BreadcrumbsComponent,
    },
    watch: {
      '$route': {
          immediate: true,
          handler() {
              document.title = 'О нас'
          },
      },
  },
}
</script>

<style>
.n-ul {
    list-style-type: disc;
}
.n-ul li {
    color: #666;
}
</style>