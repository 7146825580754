<template>
    <HeaderComponent />
    <BreadcrumbsComponent 
        pageName="Сервис"
        pageTitle="Сервисное обслуживание"
    />
    <section class="blog-detail-section blog-left-section blog-right-section blog-v1-section blog-v2-section section-box">
        <div class="container">
            <div class="woocommerce">
                <div class="row">
                    <div class="col-12">
                        <div class="blog-left-detail">
                            <!-- Blog 1 -->
                            <div class="blog-detail">
                                <h3>Что Значит сервисное обслуживание в "АБ Сервис"?</h3>
                                <h3>ОПТИМАЛЬНЫЙ КОНТРОЛЬ ЗАТРАТ И НЕПРЕРЫВНАЯ ЗАЩИТА ВАШЕЙ МАШИНЫ.</h3>
                                <p>
                                    Для обеспечения непрерывной  работы АБ Сервис предлагает вам первоклассное техобслуживание. Помимо опциональной послеуборочной проверки, он  включает  – все проверки и услуги по техобслуживанию вашей машины. Вы можете быть спокойны: у нас есть опыт ремонта и восстановления узлов, за которые не берутся официальные сервисы, более того, мы предоставляем гарантию на все ремонтные и восстановительные работы! Квалификация сотрудников поможет произвести точный расчет затрат.
                                </p>
                                <p>
                                <n-ul>
                                    <n-li>
                                        Компетентное техобслуживание профессионалов с большим опытом работы
                                    </n-li>
                                    <n-li>
                                        Оптимизация надежности вашей машины
                                    </n-li>
                                    <n-li>
                                        Применение запасных частей и эксплуатационных материалов оптимальной цены и качества
                                    </n-li>
                                    <n-li>
                                        Предсказуемые и прозрачные расходы
                                    </n-li>
                                    <n-li>
                                        Сервисная документация
                                    </n-li>
                                    <n-li>
                                        Рост стоимости вашей машины при перепродаже
                                    </n-li>
                                    <n-li>
                                        Сервисная документация
                                    </n-li>
                                </n-ul>
                                </p>
                                <p>
                                    Как только вы заключите договор на техобслуживание в АБ Сервис, вам больше не придется заботиться о техобслуживании вашей машины. АБ Сервис имеет полный план работ для следующего обслуживания и может согласовать технический осмотр машины с вами в соответствии с этим планом.
                                </p>
                                <p>
                                    АБ Сервис поможет быстро подготовить для вас привлекательное предложение на техобслуживание. Это поможет вам сэкономить время и деньги.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <FooterComponent />
</template>

<script>
import HeaderComponent from '@/components/Header/HeaderComponent.vue'
import FooterComponent from '@/components/Footer/FooterComponent.vue'
import BreadcrumbsComponent from '@/components/Widgets/BreadcrumbsComponent.vue'
export default {
    name: "ServiceView",
    components: {
        HeaderComponent,
        FooterComponent,
        BreadcrumbsComponent,
    },
    watch: {
      '$route': {
          immediate: true,
          handler() {
              document.title = 'Сервис'
          },
      },
  },
}
</script>

<style>
.n-ul {
    list-style-type: disc;
}
.n-ul li {
    color: #666;
}
</style>