<template>
    <HeaderComponent />
    <BreadcrumbsComponent 
        pageName="Каталог"
        pageTitle="Запчасти"
    />
    <CatalogContentComponent />
    <FooterComponent />
</template>

<script>
    import HeaderComponent from '@/components/Header/HeaderComponent.vue'
    import FooterComponent from '@/components/Footer/FooterComponent.vue'
    import BreadcrumbsComponent from '@/components/Widgets/BreadcrumbsComponent.vue'
    import CatalogContentComponent from '@/components/Catalog/ContentComponent.vue'

    export default {
        name: 'CatalogView',
        components: {
            HeaderComponent,
            FooterComponent,
            BreadcrumbsComponent,
            CatalogContentComponent
        },
        mounted () {
            window.scrollTo({
                top: 200,
                behavior: 'smooth'
            })
        },
        watch: {
            '$route': {
                immediate: true,
                handler() {
                    document.title = 'Каталог'
                },
            },
        },
    }
</script>