<template>
    <div class="woocommerce-pagination pt-4">
        <div v-if="goods_list_loading" class="page-numbers">
            <a class="next page-numbers">
                <n-skeleton  :sharp="false" size="medium" />
            </a>
            <a class="page-numbers">
                <n-skeleton  :sharp="false" size="medium" />
            </a>
            <a class="page-numbers">
                <n-skeleton  :sharp="false" size="medium" />
            </a>
            <a class="page-numbers">
                <n-skeleton  :sharp="false" size="medium" />
            </a>
            <a class="next page-numbers">
                <n-skeleton  :sharp="false" size="medium" />
            </a>
        </div>
        <div v-else class="row page-numbers justify-content-center">
            <div>
                <a @click="previosPage" href="#" class="next page-numbers">
                    <span><i class="fas fa-chevron-left"></i></span>
                </a>
            </div>
            <div v-if="currentPage > 3">
                <router-link 
                    v-if="q"
                    :to="{ name: 'catalog', query: {q: q, page:1} }"
                    class="page-numbers"
                >
                    <span>1</span>
                </router-link>
                <router-link 
                    v-else
                    :to="{ name: 'catalog', query: {page:1} }"
                    class="page-numbers"
                >
                    <span>1</span>
                </router-link>
            </div>
            <div v-if="currentPage > 3">
                <a class="page-numbers">
                    <span>...</span>
                </a>
            </div>
            <div 
                v-for="item in availablePagesBeforeCurrent" 
                v-bind:key="item.id"
            >
                <router-link 
                    v-if="q"
                    :to="{ name: 'catalog', query: {q: q, page:item} }"
                    class="page-numbers"
                >
                    <span>{{ item }}</span>
                </router-link>
                <router-link 
                    v-else
                    :to="{ name: 'catalog', query: {page:item} }"
                    class="page-numbers"
                >
                    <span>{{ item }}</span>
                </router-link>
            </div>
            <div>
                <a class="page-numbers current">
                    <span>{{ currentPage }}</span>
                </a>
            </div>
            <div 
                v-for="item in availablePagesAfterCurrent" 
                v-bind:key="item.id"
            >
                <router-link 
                    v-if="q"
                    :to="{ name: 'catalog', query: {q: q, page:item} }"
                    class="page-numbers"
                >
                    <span>{{ item }}</span>
                </router-link>
                <router-link 
                    v-else
                    :to="{ name: 'catalog', query: {page:item} }"
                    class="page-numbers"
                >
                    <span>{{ item }}</span>
                </router-link>
            </div>
            <div v-if="(pageQty - currentPage) > 2">
                <a class="page-numbers">
                    <span>...</span>
                </a>
            </div>
            <div v-if="(pageQty - currentPage) > 2">
                <router-link 
                    v-if="q"
                    :to="{ name: 'catalog', query: {q: q, page:pageQty} }"
                    class="page-numbers"
                >
                    <span>{{ pageQty }}</span>
                </router-link>
                <router-link 
                    v-else
                    :to="{ name: 'catalog', query: {page:pageQty} }"
                    class="page-numbers"
                >
                    <span>{{ pageQty }}</span>
                </router-link>
            </div>
            <div>
                <a @click="nextPage" href="#" class="next page-numbers">
                    <span><i class="fas fa-chevron-right"></i></span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CatalogPagination",
    computed: {
        q () {
            return this.$route.query.q
        },
        goods_list_loading () {
            return this.$store.getters.goods_list_loading
        },
        currentPage () {
            return this.$store.getters.current_page
        },
        goodsQty () {
            return this.$store.getters.goods_qty
        },
        pageQty () {
            return this.$store.getters.page_qty
        },
        availablePagesBeforeCurrent () {
            return this.$store.getters.availablePagesBeforeCurrent
        },
        availablePagesAfterCurrent () {
            return this.$store.getters.availablePagesAfterCurrent
        }
    },
    methods: {
        setCurrentPage (page_num) {
            if (!this.$route.query.page == '') {
                this.$store.dispatch('setCurrentPage', page_num)
            } else {
                this.$store.dispatch('setCurrentPage', 1)
            }
        },
        loadCurrentGoodsList(data) {
            this.$store.dispatch('getGoodsList', data) 
        },
        nextPage: function(el) {
            el.preventDefault()
            if (this.goods_list_loading == false) {
                if (!(this.currentPage == this.pageQty)) {
                    this.setCurrentPage(this.currentPage + 1)
                    if (!this.q=='') {
                        this.$router.push({ name: 'catalog', query: {q:this.q, page: this.currentPage } })
                    } else {
                        this.$router.push({ name: 'catalog', query: {page: this.currentPage}})
                    }
                }    
            }
        },
        previosPage: function(el) {
            el.preventDefault()
            if (this.goods_list_loading == false) {
                if (this.currentPage > 1) {
                    this.setCurrentPage(this.currentPage - 1)
                    if (!this.q=='') {
                        this.$router.push({ name: 'catalog', query: {q:this.q, page:this.currentPage } })
                    } else {
                        this.$router.push({ name: 'catalog', query: {page: this.currentPage}})
                    }
                }
            }    
        },
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                let query = this.$route.query
                if (query.page != '') {
                    this.setCurrentPage(parseInt(query.page))
                } else {
                    this.setCurrentPage(1)
                }
                let data = {
                    "page" : this.currentPage
                }
                if (this.q!='') {
                    data.q = this.q
                }
                this.loadCurrentGoodsList(data)
                window.scrollTo({
                    top: 200,
                    behavior: 'smooth'
                })
            }
        }
    }    
}
</script>