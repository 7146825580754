<template>
    <div class="menu-desktop">
        <div class="container menu-desktop-inner">
            <!-- Logo -->
            <div class="logo">
                <router-link :to="{ name: 'home' }"><img :src="logoImage" alt="logo"></router-link>
            </div>
            <!-- Main Menu -->
            <nav class="main-menu">
                <ul>
                    <li class="menu-item">
                        <router-link :to="{ name: 'home' }">Главная</router-link> 
                    </li>
                    <li class="menu-item">
                        <router-link :to="{ name: 'support' }">Поддержка</router-link> 
                    </li>
                    <li class="menu-item">
                        <router-link :to="{ name: 'service' }">Сервис</router-link>
                    </li>
                    <li class="menu-item">
                        <router-link :to="{ name: 'catalog', query: {page: 1} }">Запчасти</router-link> 
                    </li>
                    <li class="menu-item">
                        <router-link :to="{ name: 'about' }">О нас</router-link> 
                    </li>
                    <li class="menu-item">
                        <router-link :to="{ name: 'contact' }">Контакты</router-link> 
                    </li>

                </ul>
            </nav>
            <router-link :to="{ name: 'cart' }">
                <div class="cart-button">
                    <div class="cart-form">
                        <n-space :size="24">
                            <n-badge :value="cartQty" show-zero>
                                <span class="cart-icon">
                                    <i class="fas fa-shopping-cart"></i>
                                </span>
                            </n-badge>
                        </n-space>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import { useCookies } from "vue3-cookies"
import { cookiesToken } from '@/main.js'
import logoImage from "@/assets/images/logo/logo-black.png"
export default {
    name: "MenuDesctopComponent",
    data () {
        return {
            logoImage
        }
    },
    setup() {
        const { cookies } = useCookies()
        return { cookies }
    },
    computed: {
        cartQty () {
            return this.$store.getters.cartQty
        },
        sessionToken () {
            return this.$store.getters.sessionToken
        }
    },
    methods: {
        loadCart (sessionToken) {
            this.$store.dispatch('loadCart', sessionToken)
        },
        loadSessionToken () {
            this.$store.dispatch('getSessionToken').then(() => {
                this.cookies.set(`${cookiesToken}`, this.sessionToken)
            })
        },
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                if (!this.cookies.get(`${cookiesToken}`)) {
                    this.loadSessionToken()
                } else {
                    this.$store.dispatch('setSessionToken', this.cookies.get(`${cookiesToken}`))
                }
                if (this.sessionToken) {
                    this.loadCart(this.sessionToken)
                }
            },
        }
    }
}
</script>
