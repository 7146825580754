<template>
    <td class="product-name">
        <router-link :to="{ name: 'good', params: { id:goodInfo.id} }">{{ goodInfo.art }}</router-link>
    </td>
    <td class="product-name">
        <router-link :to="{ name: 'good', params: { id:goodInfo.id} }">{{ goodInfo.name }}</router-link>
    </td>
    <td class="product-quantity">
        <div class="quantity">
            <n-input-number 
                v-model:value="qty" 
                size="large" 
                @update:value="setGoodValue"
                :validator="validator"
                :update-value-on-input="false"
             />
        </div>
    </td>
    <td class="product-remove">
        <a @click="deleteGoodValue" href="#" class="remove"><i class="la la-close"></i></a>
    </td>
</template>

<script>
export default {
    name: "UserCartGoodComponent",
    props: ["goodInfo", "qnt"],
    setup() {
        return {
            validator: (x) => x > 0,
        }
    },    
    data () {
        return {
            loading: false,
            qty: Math.floor(this.qnt)
        }
    },
    computed: {
        sessionToken () {
            return this.$store.getters.sessionToken
        }
    },
    methods: {
        setGoodValue () {
            this.loading = true
            this.$store.dispatch('addSetDelCartItem', 
            {
                good_id: this.goodInfo.id,
                quantity: this.qty,
                sessionToken: this.sessionToken,
                action: 'set'
            }).then(() => {
                this.loading = false
            })
        },
        deleteGoodValue (el) {
            el.preventDefault()
            this.loading = true
            this.$store.dispatch('addSetDelCartItem', 
            {
                good_id: this.goodInfo.id,
                quantity: this.qty,
                sessionToken: this.sessionToken,
                action: 'del'
            }).then(() => {
                this.loading = false
            })
        }
    }
}
</script>

<style scoped>

</style>