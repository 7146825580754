<template>
    <HeaderComponent />
    <BreadcrumbsComponent
      :pageName="'Корзина'"
      :pageTitle="'Корзина'"
     />
    <UserCartComponent />
    <FooterComponent />
  </template>
  
  <script>
  import HeaderComponent from '@/components/Header/HeaderComponent.vue'
  import FooterComponent from '@/components/Footer/FooterComponent.vue'
  import UserCartComponent from '@/components/User/UserCartComponent.vue'
  import BreadcrumbsComponent from '@/components/Widgets/BreadcrumbsComponent.vue'
  
  export default {
    name: 'IndexView',
    components: {
      HeaderComponent,
      FooterComponent,
      UserCartComponent,
      BreadcrumbsComponent
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                document.title = 'Корзина'
            },
        },
    },
  }
  </script>
  