<template>
  <HeaderComponent />
  <FooterComponent />
</template>

<script>
import HeaderComponent from '@/components/Header/HeaderComponent.vue'
import FooterComponent from '@/components/Footer/FooterComponent.vue'

export default {
  name: 'IndexView',
  components: {
    HeaderComponent,
    FooterComponent,
  },
  watch: {
      '$route': {
          immediate: true,
          handler() {
              document.title = 'Главная'
          },
      },
  },
}
</script>
