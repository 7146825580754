<template>
    <div class="top-header">
        <div class="container">
            <div class="top-header-inner">
                <span>Best services quality for customers.</span>
                <div class="header-socials">
                    <span>Follow Us:</span>
                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                    <a href="#"><i class="fab fa-whatsapp"></i></a>
                    <a href="#"><i class="fab fa-twitter"></i></a>
                    <a href="#"><i class="fab fa-behance"></i></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TopHeaderComponent"
}
</script>