<template>
    <div class="col-lg-3 col-xl-3 col-md-6 col-sm-6 col-12">
        <div class="footer-item">
            <h4><a href="#">Контакты</a></h4>
            <div class="contact-detail">
                <i class="la la-home"></i>
                <div class="contact-detail-inner">
                    <span>308008, г. Белгород, Ворошилова 2А офис 304</span>
                </div>
            </div>
            <div class="contact-detail">
                <i class="la la-phone"></i>
                <div class="contact-detail-inner">
                    <span><a href="tel:+74722421157">+7 (4722) 42-11-57</a></span>
                </div>
            </div>
            <div class="contact-detail">
                <i class="la la-mobile-phone"></i>
                <div class="contact-detail-inner">
                    <span><a href="tel:+79606228864">+7 (960) 622-88-64</a></span>
                </div>
            </div>
            <div class="contact-detail">
                <i class="la la-envelope"></i>
                <div class="contact-detail-inner">
                    <span>abservice31anna@mail.ru</span>
                </div>
            </div>
            <div class="contact-detail">
                <i class="la la-envelope"></i>
                <div class="contact-detail-inner">
                    <span>ababko31@mail.ru</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FooterContactsComponent"
}
</script>