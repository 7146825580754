<template>
  <n-message-provider>
    <router-view/>
    <MessageComponent />
  </n-message-provider>
  <BackToTopComponent />
</template>

<script>
import MessageComponent from '@/components/Widgets/MessageComponent.vue'
import BackToTopComponent from '@/components/Widgets/BackToTopComponent.vue'
import { defineComponent } from "vue"
export default defineComponent({
  components: {
    MessageComponent,
    BackToTopComponent
  }
})
</script>
