<template>
    <section class="cart-page section-box">
        <div class="container">
            <div class="woocommerce row justify-content-center">
                <div v-if="goodInfoLoadingStatus" class="cart-collaterals row col-11">
                    <div class="col-lg-5 mt-4">
                        <n-image
                            width="100%"
                            :src="imagePreview"
                        />
                    </div>
                    <div  class="col-lg-7 mt-4 cart_totals calculated_shipping">
                        <h3 class="text-block block-title"><n-skeleton  :sharp="false" size="medium" /></h3>
                        <table class="shop_table shop_table_responsive">
                            <tbody>
                                <tr><n-skeleton  :sharp="false" size="large" /></tr>
                                <tr><n-skeleton  :sharp="false" size="large" /></tr>
                                <tr><n-skeleton  :sharp="false" size="large" /></tr>
                                <tr><n-skeleton  :sharp="false" size="large" /></tr>
                                <tr><n-skeleton  :sharp="false" size="large" /></tr>
                            </tbody>
                        </table>
                        <div class="wc-proceed-to-checkout row mt-2">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4 mt-3">
                                <n-skeleton :width="150" round size="medium" />
                            </div>
                            <div class="col-12 col-sm-6 col-md-8 col-lg-8 mt-3">
                                <n-skeleton :width="150" round size="medium" />
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="cart-collaterals row col-11">
                    <div class="col-lg-5 mt-4">
                        <n-image
                            width="100%"
                            :src="imagePreview"
                        />
                    </div>
                    <div class="col-lg-7 mt-4 cart_totals calculated_shipping">
                        <h3 class="text-block block-title">{{ goodInfo.name }}</h3>
                        <table class="shop_table shop_table_responsive">
                            <tbody>
                                <tr class="cart-subtotal">
                                    <th>Артикул:</th>
                                    <td>
                                        <span class="woocommerce-Price-amount amount">
                                            <span class="woocommerce-Price-currencySymbol"></span>
                                            {{ goodInfo.art }}
                                        </span>
                                    </td>
                                </tr>
                                <tr class="cart-subtotal">
                                    <th>Остаток:</th>
                                    <td>
                                        <span class="woocommerce-Price-amount amount">
                                            <span class="woocommerce-Price-currencySymbol"></span>
                                            {{ goodInfo.balance }}
                                        </span>
                                    </td>
                                </tr>
                                <tr v-if="goodInfo.manufacturer" class="cart-subtotal">
                                    <th>Производитель:</th>
                                    <td>
                                        <span class="woocommerce-Price-amount amount">
                                            <span class="woocommerce-Price-currencySymbol"></span>
                                            {{ goodInfo.manufacturer.name }}
                                        </span>
                                    </td>
                                </tr>
                                <tr v-if="goodInfo.category" class="cart-subtotal">
                                    <th>Категория:</th>
                                    <td>
                                        <span class="woocommerce-Price-amount amount">
                                            <span class="woocommerce-Price-currencySymbol"></span>
                                            {{ goodInfo.category.name }}
                                        </span>
                                    </td>
                                </tr>
                                <tr v-if="goodInfo.description" class="shipping">
                                    <th>Описание:</th>
                                    <td>
                                        {{ goodInfo.description }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="wc-proceed-to-checkout row mt-2">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4 mt-3">
                                <n-input-number 
                                    v-model:value="qty" 
                                    size="large" 
                                    :validator="validator"
                                />
                            </div>
                            <div class="col-12 col-sm-6 col-md-8 col-lg-8 mt-3">
                                <button 
                                    v-if="!cartAddLoading"
                                    @click="addToCart"
                                    class="checkout-button button alt wc-forward au-btn au-btn-green btn-small"
                                >В корзину
                                </button>
                                <button 
                                    v-else
                                    style="padding: 18px 57px;"
                                    class="checkout-button button alt wc-forward au-btn au-btn-green btn-small"
                                >
                                <n-space>
                                    <n-spin size="small" />
                                </n-space>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import imagePreview from "@/assets/images/imagePreview.png"
export default {
    name: "GoodDetailsComponent",
    setup() {
        return {
            validator: (x) => x > 0,
        }
    }, 
    data () {
        return {
            qty: 1,
            cartAddLoading: false,
            imagePreview
        }
    },
    computed: {
        sessionToken () {
            return this.$store.getters.sessionToken
        },
        goodInfo () {
            return this.$store.getters.good
        },
        goodInfoLoadingStatus () {
            return this.$store.getters.good_info_loading
        },
    },
    methods: {
        loadGoodInfo(id) {
            this.$store.dispatch('loadGoodInfo', id)
        },
        addToCart () {
            if (this.goodInfo && this.qty) {
                this.cartAddLoading = true
                this.$store.dispatch('addSetDelCartItem', 
                {
                    good_id: this.goodInfo.id,
                    quantity: this.qty,
                    sessionToken: this.sessionToken,
                    action: 'add'
                }).then(() => {
                    this.cartAddLoading = false
                })
            }
        },
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                this.loadGoodInfo(this.$route.params.id)
            },
        }
    },
}
</script>

<style scoped>
.checkout-button {
    cursor: pointer;
}
.wc-proceed-to-checkout {
    padding: 0px 20px;
}
@media (min-width: 992px) {
    .cart-page .woocommerce .cart-collaterals {
        width: 37.8%;
        float: left;
    }
}
@media (max-width: 767px) {
    .checkout-button {
        width: 150px !important;
    }
}
</style>