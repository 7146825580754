import Axios from 'axios'
import {backendPath} from "@/main.js"

export default {
    state: {
        cart: [],
        cart_loading: false,
        sendCartResultMessage: "",
        addCartItemMessage: "",
    },
    getters: {
        cart: state => {
            return state.cart
        },
        cartQty: state => {
            return state.cart.length
        },
        cartAmount: state => {
            if (state.cart.length > 0) {
                return state.cart.reduce((acc,item) => acc += (item.quantity*item.good.price), 0)
            } else {
                return 0
            }
        },
        cartItemById: state => id => {
            return state.cart.find(cartItem => cartItem.good.id === id)
        },
        cart_loading: state => {
            return state.cart_loading
        },
        sendCartResultMessage: state => {
            return state.sendCartResultMessage
        },
        addCartItemMessage: state => {
            return state.addCartItemMessage
        }
    },
    mutations: {
        loadCartData (state, payload) {
            state.cart = payload
        },
        cartLoadingSwitch (state, payload) {
            state.cart_loading = payload
        },
        loadSendCartResultMessage (state, payload) {
            state.sendCartResultMessage = payload
        },
        loadAddCartItemMessage (state, payload) {
            state.addCartItemMessage = payload
        }
    },
    actions: {
        async addSetDelCartItem({commit}, params) {
            let good_id = params.good_id
            let quantity = params.quantity
            let action = params.action
            let sessionToken = params.sessionToken
            commit('cartLoadingSwitch', true)
            commit('loadAddCartItemMessage', "")
            let data = {
                "id" : good_id,
                "qnt": Number(quantity),
                "token": sessionToken
            }
            let url = ''
            if (action == 'add') {
                url = `${backendPath}/api/v1/cart/add/?${new URLSearchParams(data).toString()}`
            } else if (action == 'set') {
                url = `${backendPath}/api/v1/cart/set/?${new URLSearchParams(data).toString()}`
            } else if (action == 'del') {
                url = `${backendPath}/api/v1/cart/delete/?${new URLSearchParams(data).toString()}`
            }
            await Axios({
                method: 'get',
                url: url,
            }).then(response => {
                let cartList = response.data.data
                commit('loadCartData', cartList)
                if (action == 'add') {
                    commit('loadAddCartItemMessage', "Товар добавлен в корзину!")
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                commit('cartLoadingSwitch', false)
            })
        },
        async loadCart({commit}, sessionToken) {
            commit('cartLoadingSwitch', true)
            let url = `${backendPath}/api/v1/cart/?token=${sessionToken}`
            await Axios({
                method: 'get',
                url: url,
            }).then(response => {
                let cartList = response.data.data
                commit('loadCartData', cartList)
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                commit('cartLoadingSwitch', false)
            })
        },
        async sendCart ({commit}, params) {
            commit('loadSendCartResultMessage', "")
            let data = {
                "token": params.sessionToken,
                "name": params.name,
                "email": params.email,
                "phone": params.phone,
            }
            let url = `${backendPath}/api/v1/cart/send/?${new URLSearchParams(data).toString()}`
            await Axios({
                method: 'get',
                url: url,
            }).then(response => {
                let cartList = response.data.data
                commit('loadCartData', cartList)
                commit('loadSendCartResultMessage', "Заказ успешно отправлен!")
            }).catch(error => {
                console.log(error)
            })
        }
    }
}