<template>
    <div class="col-lg-3 col-xl-3 col-md-6 col-sm-6 col-12">
        <div class="footer-item widget_tag_cloud">
            <h4><a href="#">Полезные ссылки</a></h4>
            <div class="tags-item tagcloud">
                <span><a href="#">Записаться на сервис</a></span>
                <span><a href="#">Заказать запчасти</a></span>
                <span><a href="#">Обратный звонок</a></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ImportantLinksComponent"
}
</script>