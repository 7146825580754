<template>
    <header class="header">
        <div class="show-desktop-header header-hp-1">
            <TopHeaderComponent />
            <MenuDesctopComponent />
        </div>
        <MobileHeaderComponent />
    </header>
</template>

<script>
import TopHeaderComponent from '@/components/Header/TopHeaderComponent.vue'
import MenuDesctopComponent from '@/components/Header/MenuDesctopComponent.vue'
import MobileHeaderComponent from '@/components/Header/MobileHeaderComponent.vue'
export default {
    name: "HeaderComponent",
    components: {
        TopHeaderComponent,
        MenuDesctopComponent,
        MobileHeaderComponent
    }
}
</script>