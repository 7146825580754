<template>
    <div class="sub-footer">
        <p>Copyright © 2019 <a href="https://themeforest.net/user/authemes/portfolio?ref=AuThemes">AuThemes</a>. <a class="footer-btn-buy" href="https://themeforest.net/cart/add_items?item_ids=22980836">Get the template</a></p>
    </div>
</template>

<script>
export default {
    name: "SubFooterComponent"
}
</script>