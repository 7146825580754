import { createStore } from 'vuex'
import goods_list from './modules/Catalog/goods_list'
import cart from './modules/User/cart'
import session from './modules/User/session'
import good from './modules/Catalog/good'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    goods_list,
    good,
    cart,
    session,
  }
})
