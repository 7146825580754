<template>
    <div class="col-lg-3 col-xl-3 col-md-6 col-sm-6 col-12">
        <div class="footer-item">
            <h4><a href="#">Меню</a></h4>
            <ul>
                <li>
                    <router-link :to="{ name: 'home' }">
                        <i class="la la-angle-right"></i>
                        Главная
                    </router-link>
                </li>
                <li>
                    <a href="#">
                        <i class="la la-angle-right"></i>
                        Поддержка
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="la la-angle-right"></i>
                        Сервис
                    </a>
                </li>
                <li>
                    <router-link :to="{name: 'catalog', query: {page: 1}}">
                        <i class="la la-angle-right"></i>
                        Запчасти
                    </router-link>
                </li>
                <li>
                    <a href="#">
                        <i class="la la-angle-right"></i>
                        О нас
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i class="la la-angle-right"></i>
                        Контакты
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "FooterMenuComponent"
}
</script>