<template>
    <HeaderComponent />
    <BreadcrumbsComponent 
        pageName="Контакты"
        pageTitle="Контакты"
    />
    <section class="contact-v2-section contact-form-section section-box">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12">
                    <div class="contact-total">
                        <h2 class="special-heading">ООО «АБ Сервис»</h2>
                        <!-- <p class="text">Donec diam nisi, condimentum in vestibulum ullam corper ultrices dui. Quisque neque libero, condi mentum id neque ut, pellentesque bibendum eros.</p> -->
                        <div class="contact-content">
                            <div class="contact-icon">
                                <i class="la la-info-circle"></i>
                            </div>
                            <div class="contact-info">
                                <p><span>ИНН:</span> 3123222866</p>
                                <p><span>КПП:</span> 312301001</p>
                                <p><span>ОГРН:</span> 1103123015291</p>
                            </div>
                        </div>
                        <div class="contact-content">
                            <div class="contact-icon">
                                <i class="la la-map"></i>
                            </div>
                            <div class="contact-info">
                                <span>Адрес:</span>
                                <p>308008, г. Белгород, Ворошилова 2А офис 304</p>
                            </div>
                        </div>
                        <div class="contact-content">
                            <div class="contact-icon">
                                <i class="fa fa-envelope" aria-hidden="true"></i>
                            </div>
                            <div class="contact-info">
                                <span>Email:</span>
                                <p><a href="mailto:ababko31@mail.ru" class="tel">ababko31@mail.ru</a></p>
                                <p><a href="mailto:ababko31@mail.ru" class="tel">abservice31anna@mail.ru</a></p>
                            </div>
                        </div>
                        <div class="contact-content">
                            <div class="contact-icon">
                                <i class="la la-phone"></i>
                            </div>
                            <div class="contact-info">
                                <span>Телефоны:</span>
                                <p><a href="tel:+74722421157" class="tel">+7 (4722) 42-11-57</a></p>
                                <p><a href="tel:+79606228864" class="tel">+7 (960) 622-88-64</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12">
                    <div class="quote-form">
                        <div class="form-contact js-contact-form">
                            <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A38cae952180863cb35832f8f857186c9dd01c01fcbb978dc138f5b18ca11621e&amp;source=constructor" width="100%" height="450" frameborder="0"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <FooterComponent />
</template>

<script>
import HeaderComponent from '@/components/Header/HeaderComponent.vue'
import FooterComponent from '@/components/Footer/FooterComponent.vue'
import BreadcrumbsComponent from '@/components/Widgets/BreadcrumbsComponent.vue'
export default {
    name: "ContactView",
    components: {
        HeaderComponent,
        FooterComponent,
        BreadcrumbsComponent,
    },
    watch: {
      '$route': {
          immediate: true,
          handler() {
              document.title = 'Контакты'
          },
      },
  },
}
</script>

<style scoped>
.quote-form {
    padding-top:50px;
}
</style>