import { createRouter, createWebHistory } from 'vue-router'
import IndexView from '../views/IndexView.vue'
import CatalogView from '../views/CatalogView.vue'
import CartView from '../views/CartView.vue'
import GoodDetailsView from '../views/GoodDetailsView.vue'
// import SupportView from '../views/SupportView.vue'
import SupportView from '../views/SupportView.vue'
import ServiceView from '../views/ServiceView.vue'
import AboutView from '../views/AboutView.vue'
import ContactView from '../views/ContactView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: IndexView
  },
  {
    path: '/cart/',
    name: 'cart',
    component: CartView
  },
  {
    path: '/support/',
    name: 'support',
    component: SupportView
  },
  {
    path: '/service/',
    name: 'service',
    component: ServiceView
  },
  {
    path: '/about/',
    name: 'about',
    component: AboutView
  },
  {
    path: '/contact/',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/catalog/',
    children: [
      {
        path: '',
        name: 'catalog',
        component: CatalogView
      },
      {
        path: 'good/:id/',
        name: 'good',
        component: GoodDetailsView
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
